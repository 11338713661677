@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.note {
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
  max-width: 500px;
}

.question {
  &:not(:last-child) {
    margin: 0 0 $padding-default;
  }

  .label {
    @include label;

    span {
      font-weight: 400;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

:global {
  .react-stars {
    line-height: 50px;
  }
}
