@import "@assets/styles/variables.scss";

.container {
  display: flex;
  height: 100vh;
  align-items: center;
  padding: 0 0 0 40px;

  .form {
    background-color: $color-shark;
    color: $color-white;
    padding: 40px;
    width: 400px;

    .title {
      display: flex;
      gap: 20px;
      font-size: 32px;
      line-height: 1;
      font-weight: 600;
      line-height: 24px;
      cursor: default;
      align-items: center;
      margin: 0 0 20px;

      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
      }
    }

    .subtitle {
      font-size: 24px;
      margin: 20px 0;

      span {
        font-weight: 600;
      }
    }

    .row {
      margin: 0 0 10px;

      input {
        background-color: $color-grey-dark-2;
        color: $color-white;
      }
    }

    .button {
      width: 100%;
      margin: 10px 0 0;
    }

    .registration {
      margin: 10px 0;
      font-size: 12px;

      a {
        color: $color-white;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .container {
    width: 100%;
    padding: 20px;

    .form {
      padding: 20px;
      width: 100%;
    }
  }
}
