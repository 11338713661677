@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.title {
  margin: 10px 0 0;
  font-size: 30px;
  line-height: 40px;
}

.note {
  margin: 15px 0;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px;
  min-height: 73px;

  .customerDropdown {
    width: 400px;
  }

  .button {
    margin: 10px auto 0 20px;

    &:disabled {
      svg {
        @include rotation;
      }
    }
  }

  .address {
    background-color: $color-geyser;
    padding: 10px 20px;

    .label {
      color: $color-grey-dark-2;
      font-size: 14px;
      margin: 0 0 5px;
    }
  }
}

.tableTop {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 10px;

  .subtitle {
    font-weight: 600;
    font-size: 20px;
  }

  .filters {
    margin-left: auto;
    display: flex;
    gap: $padding-default;
  }
}

:global {
  .rsf__option {
    .rsf__option-note {
      font-size: 14px;
      color: $color-grey-dark-2;
    }

    &:hover {
      .rsf__option-note {
        color: $color-white;
      }
    }
  }
}

.empty {
  background-color: $color-white;
  color: $color-nevada;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 40px;
  gap: 20px;
  cursor: default;
  margin: 70px 0 0;

  svg {
    height: 70px;
    width: 70px;
  }
}

.table {
  background-color: $color-white;
  min-height: 224px;

  thead {
    th {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
        width: 40px;
      }
      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    td {
      &:nth-child(1),
      &:nth-child(2) {
        text-align: center;
        width: 40px;
      }
      &:last-child {
        text-align: right;
        font-weight: 500;
      }
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px;
  padding: 20px 0 0;

  .pfiInfo {
    width: 35%;

    .field {
      margin: 0 0 20px;

      .label {
        font-weight: 600;
        margin: 0 0 5px;

        span {
          font-weight: normal;
        }
      }
    }
  }
}

@mixin table-grid {
  line-height: $cell-height;
  flex-grow: 1;

  &:nth-child(1) {
    width: 6.5%;
    text-align: center;
  }
  &:nth-child(2) {
    width: 60%;
  }
  &:nth-child(3) {
    width: 20%;
    text-align: center;

    input {
      text-align: center;
    }
  }
  &:nth-child(4) {
    width: 13.5%;
    min-width: 200px;
  }
}

.tableWrapper {
  min-height: 214px;
  background-color: $color-white;
}

.otherSubtitle {
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0 10px;
}

.otherCostTable {
  .thead {
    display: flex;
    background-color: $color-geyser;

    .th {
      @include table-grid;
      padding: 0 12px;
    }
  }

  .tbody {
    border-bottom: 1px solid $color-geyser;

    .row {
      display: flex;
      border: 1px solid $color-white;
      background-color: $color-white;

      &:not(:last-child) {
        border-bottom-color: $color-geyser;
      }

      &.editable {
        background-color: $color-botticelli;
        border-color: $color-picton-blue;
      }

      .td {
        @include table-grid;
        padding: 5px;
        line-height: $field-height;
        position: relative;
      }
    }
  }
}

.tfoot {
  display: flex;
  justify-content: space-between;
  background-color: $color-geyser;

  .td {
    @include table-grid;
    font-weight: 600;
    padding: 0 12px;
    position: relative;
  }
}

.buttonAdd {
  margin: 0 0 10px;
  color: $color-lochmara;
  font-weight: 600;

  svg {
    width: 12px;
    height: 12px;
  }
}

.actions {
  display: flex;
  gap: 20px;
  height: 100%;

  button {
    height: 100%;
  }
}

.buttonSuccess {
  color: $color-jewel;
  font-weight: 600;
}

.buttonEdit {
  color: $color-lochmara;
  font-weight: 600;
}
