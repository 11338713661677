@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.container {
  padding: $padding-default;
}

.item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $color-geyser;
  }

  &:nth-child(1) {
    padding-top: 0;
  }

  .iconDocMoney {
    fill: $color-nevada;
    margin-right: -10px;
  }

  .iconDoc {
    fill: $color-nevada;
  }

  .info {
    line-height: 1.3;
    .date {
      color: $color-grey-dark-2;
      font-size: 12px;
    }
  }

  .downloadButton {
    margin-left: auto;
  }
}
