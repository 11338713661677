@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.header {
  background-color: $color-lochmara;
  color: $color-catskill;
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 $padding-default;

  .logo {
    max-width: 40%;

    img {
      height: 60px;
    }
  }

  .buttons {
    margin: 0 0 0 auto;
    display: flex;
  }

  .button {
    @include centered;
    gap: 8px;
    padding: 6px 20px;
    line-height: 1.2;
    color: $color-white;
    background-color: $color-venice;
    border-radius: 25px;
    margin: 0 $padding-default 0 auto;
    transition: 0.2s background-color linear;

    &:hover {
      background-color: lighten($color: $color-venice, $amount: 5%);
    }

    svg {
      fill: $color-white;
      width: $icon-size;
      height: $icon-size;
    }

    b {
      font-weight: 600;
    }

    &.buttonCustomer {
      background-color: $color-green-grass;
      text-transform: uppercase;
      font-weight: 300;

      &:hover {
        background-color: lighten($color: $color-green-grass, $amount: 5%);
      }
    }
  }

  .user {
    background-color: $color-venice;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 0 0 $padding-default;
    width: $aside-width;

    .logoWfp {
      width: 50px;
    }

    .content {
      padding: 0 $padding-default;
      line-height: 1.2;
      cursor: default;

      .name {
        font-weight: 500;
        font-size: 18px;
      }

      .actions {
        display: flex;
        justify-content: space-between;

        .logout {
          color: $color-loblolly;
          font-size: 14px;
        }
      }
    }
  }

  .menuButton {
    padding: 0;
    margin: 0 0 0 20px;

    svg {
      width: 40px;
      height: 40px;
    }

    &:hover {
      background: none;
    }
  }
}

.mobileMenuInfo {
  .button {
    @include centered;
    gap: 8px;
    padding: 6px 20px;
    line-height: 1.2;
    color: $color-white;
    background-color: $color-venice;
    border-radius: 25px;
    margin: 10px 20px 20px;

    svg {
      fill: $color-white;
      width: $icon-size;
      height: $icon-size;
    }

    b {
      font-weight: 600;
    }
  }

  .user {
    margin: 20px;
    color: $color-white;

    .name {
      font-weight: 500;
      font-size: 18px;
    }

    .logout {
      margin: 20px 0 0;
      color: $color-loblolly;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .wrapper {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .header {
    padding: 0;
    justify-content: start;
    gap: 20px;

    .button,
    .user {
      display: none;
    }

    .logo {
      max-width: none;
      width: 65%;

      img {
        height: auto;
      }
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .menuButton {
    display: none;
  }
}
