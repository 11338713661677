@import "@assets/styles/variables.scss";

.modal {
  min-width: 600px;
}

.box {
  margin: 0 0 20px;
}

.defectRow {
  border-top: 1px solid $color-geyser;
  height: $field-height;
  line-height: $field-height;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  gap: $padding-default;

  .approval {
    color: $color-pizza;
    font-weight: 500;
  }

  .fromBooking {
    color: $color-oslo;
    font-weight: 500;
  }

  &:last-child {
    border-bottom: 1px solid $color-geyser;
  }
}
