@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.top {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px;

  .titleLine {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .title {
    font-size: 30px;
    line-height: 40px;
    display: flex;
    gap: 10px;

    .number {
      font-weight: 500;
      flex-shrink: 0;
    }
  }

  .jobStatus {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 15px 0 0;
  cursor: default;

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      fill: $color-nevada;
    }
  }
}

.container {
  display: flex;
  gap: $padding-default;
  flex-wrap: wrap;

  .boxWrapper {
    width: calc(50% - 10px);
    display: flex;
    gap: $padding-default;
    flex-direction: column;

    > div {
      height: 100%;
    }
  }
}
