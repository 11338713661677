@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.wrapper {
  padding: $padding-default;
}

.title {
  @include widget-title;
}

.labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $padding-default;
  margin: $padding-default 0 0;

  .label {
    display: flex;
    align-items: center;

    .dot {
      width: 16px;
      height: 16px;
    }

    .text {
      padding: 0 10px;
      line-height: 16px;
      cursor: default;
    }
  }
}
