@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

$status-field-height: 32px;

@mixin dropdown {
  flex-shrink: 0;
}

.dropdown {
  @include dropdown;
}

.status {
  @include dropdown;
  @include text-cropping;
  height: $status-field-height;
  line-height: $status-field-height;
  font-size: 16px;
  border: 0;
  border-radius: calc(#{$status-field-height} / 2);
  color: $color-white;
  background-color: $color-grey-middle-4;
  font-weight: 500;
  padding: 0 10px;
  text-align: center;
}

.modal {
  width: 520px;
}

.note {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
