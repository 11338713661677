@import "@assets/styles/variables.scss";

.modal {
  width: 530px;
  max-width: 60vw;
}

.note {
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 $padding-default;
}
