@import "@assets/styles/variables.scss";

.subtitle {
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 600;

  span {
    font-weight: normal;
  }
}

.note {
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
  max-width: 500px;
}

.defectRow {
  border-top: 1px solid $color-geyser;
  height: $field-height;
  line-height: $field-height;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  gap: $padding-default;

  &:last-child {
    border-bottom: 1px solid $color-geyser;
  }

  .fromCustomer {
    color: $color-oslo;
  }

  .status {
    color: $color-nandor;
  }

  .actions {
    display: flex;
    gap: $padding-default;
    font-weight: 500;

    .buttonReject {
      font-weight: 500;
      color: $color-alizarin;
      &:hover {
        color: lighten($color-alizarin, 6%);
      }
    }

    .buttonApprove {
      font-weight: 500;
      color: $color-green-grass;
      &:hover {
        color: lighten($color-green-grass, 6%);
      }
    }
  }
}
