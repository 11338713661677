@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.field {
  @include input;
  @include text-cropping;
  background-color: $color-geyser;
  color: $color-grey-dark-2;
  cursor: default;
  line-height: 30px;

  span {
    color: $color-shark;
    font-size: 12px;
  }
}
