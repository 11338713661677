@import "@assets/styles/variables.scss";

.note {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.subNote {
  font-size: 12px;
  line-height: 22px;
  color: $color-grey-dark-2;
  line-height: 1;
  margin: 5px 0 10px;
}
