@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.scss';

.booking {
  font-size: 14px;
  margin: 10px 0;
}

.title {
  margin: 10px 0 0;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 10px 0 30px;
  cursor: default;

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      fill: $color-nevada;
    }
  }
}

@mixin table-grid {
  line-height: $field-height;
  flex-grow: 1;

  &:nth-child(1) {
    width: 5%;
    text-align: center;
  }
  &:nth-child(2) {
    width: 49%;
  }
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    width: 10%;
    text-align: center;
    input {
      text-align: center;
    }
  }
  &:nth-child(5) {
    @include text-cropping;
  }
  &:nth-child(6) {
    width: 16%;
  }
}

.tableWrapper {
  min-height: 208px;
  background-color: $color-white;
}

.table {
  .thead {
    display: flex;
    background-color: $color-geyser;

    .th {
      @include table-grid;
      padding: 5px;
    }
  }

  .tbody {
    border-bottom: 1px solid $color-geyser;

    .row {
      display: flex;
      border: 1px solid $color-white;
      background-color: $color-white;

      &:not(:last-child) {
        border-bottom-color: $color-geyser;
      }

      &.editable {
        background-color: $color-botticelli;
        border-color: $color-picton-blue;
      }

      .td {
        @include table-grid;
        padding: 5px;
        position: relative;
      }
    }
  }
}

.buttonAdd {
  margin: 13px 0;
  color: $color-lochmara;
  font-weight: 600;

  svg {
    width: 12px;
    height: 12px;
  }
}

.actions {
  display: flex;
  gap: 20px;
  height: 100%;

  button {
    height: 100%;
  }
}

.buttonSuccess {
  color: $color-jewel;
  font-weight: 600;
}

.buttonEdit {
  color: $color-lochmara;
  font-weight: 600;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px;
  padding: 20px 0 0;
  border-top: 1px solid $color-geyser;

  .pfiInfo {
    width: 35%;

    .field {
      margin: 0 0 20px;

      .label {
        font-weight: 600;
        margin: 0 0 5px;

        span {
          font-weight: normal;
        }
      }
    }
  }
}
