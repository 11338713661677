@import "@assets/styles/variables.scss";

.box {
  color: $color-shark;
  background-color: $color-white;

  &.full {
    width: 100%;
  }

  &.half {
    width: calc(50% - 10px);
  }

  &.quarter {
    width: calc(25% - 10px);
  }

  &.threeQuarters {
    width: calc(75% - 10px);
  }

  .title {
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid $color-geyser;
    padding: $padding-default;
    cursor: default;

    .icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $color-hawkes-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-lochmara;
    }

    .additional {
      margin-left: auto;
      display: flex;
      gap: 20px;
    }
  }
}
