@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

@mixin text-paddings {
  padding: 6px 8px;
  line-height: 1.3;
}

.chat {
  display: flex;
  flex-direction: column;
  height: calc(100% - 77px);

  .content {
    flex-grow: 1;
    flex-basis: 0;
    overflow-y: auto;

    .empty {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-geyser;
      flex-direction: column;
      gap: $padding-default;
      cursor: default;

      svg {
        width: 100px;
        height: 100px;
      }
    }

    .comments {
      padding: $padding-default $padding-default 0;

      .comment {
        display: flex;
        gap: 20px;

        .commentSide {
          display: flex;
          flex-direction: column;

          .icon {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            cursor: default;
            background: url("../../assets/img/wfp.webp") no-repeat center;
            background-size: cover;

            &.default {
              background: $color-bush;
              color: $color-bluch;
            }

            &.fromCustomer {
              background: $color-grey-dark-2;
              color: $color-white;
            }
          }

          .dots {
            flex-grow: 2;
            width: 18px;
            margin: 4px 0;
            min-height: 40px;
            border-right: 1px dashed $color-geyser;
          }
        }

        .commentMain {
          width: calc(100% - 60px);

          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            b {
              font-weight: 600;
            }

            .date {
              margin: 0 5px 0 0;
              color: $color-grey-dark-2;
              font-size: 14px;
            }
          }

          .text {
            padding: 12px 16px;
            margin: 6px 0 16px;
            border-radius: 0px 16px 16px 16px;
            background-color: $color-geyser;
            color: $color-shark;
            line-height: 24px;
            word-wrap: break-word;

            &.default {
              background-color: $color-bush;
              font-weight: 600;
            }

            &.toCustomer {
              background-color: $color-hawkes-blue;
            }

            &.fromCustomer {
              background-color: $color-grey-dark-2;
              color: $color-white;
            }

            b {
              font-weight: 600;
              color: $color-blue-lagoon;
            }
          }
        }
      }

      .anchor {
        height: $padding-default;
      }
    }
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: end;
    border-top: 1px solid $color-geyser;
    min-height: 56px;

    .button {
      width: $cell-height;
      height: 100%;
      flex-shrink: 0;
      padding: 0;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .controls {
    padding: 6px 8px;
    border-top: 1px solid $color-geyser;
  }
}

:global {
  .react-mention {
    width: 100%;
    height: 100%;
    max-width: calc(100% - 10px - #{$cell-height});

    &__control {
      height: 100%;
      @include text-paddings;
    }

    &__input {
      border: 0;
      outline: 0;
      color: $color-shark;
      @include text-paddings;

      &::placeholder {
        color: $color-oslo;
      }

      &:disabled {
        background-color: $color-silver;
      }
    }

    &__suggestions__list {
      box-shadow: $box-shadow;
      background-color: $color-catskill;
      border: 1px solid $color-geyser;
    }

    &__suggestions__item {
      padding: 3px 5px;

      &:hover {
        background-color: $color-lochmara;
        color: $color-white;
      }
    }

    &__suggestions__item--focused {
      background-color: lighten($color-lochmara, 10%);
      color: $color-white;
    }

    &__highlighter {
      margin: -1px;
    }
  }
}

.tagged {
  color: $color-blue-lagoon;
  position: relative;
  z-index: 5;
}
