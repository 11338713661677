@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

// Example
// <div class="react-select-container">
//   <div class="react-select__control">
//     <div class="react-select__value-container">...</div>
//     <div class="react-select__indicators">...</div>
//   </div>
//   <div class="react-select__menu">
//     <div class="react-select__menu-list">
//       <div class="react-select__option">...</div>
//     </div>
//   </div>
// </div>

$status-field-height: 32px;

.field {
  position: relative;

  &.fieldError {
    :global {
      .select-field.var-default {
        .rsf__control {
          background-color: $color-pippin;
          border-bottom-color: $color-alizarin;
        }
      }
    }
  }

  .error {
    @include input-error;
  }
}

:global .select-field {
  // Common styles for all variants
  .rsf__control {
    cursor: pointer;
  }

  .rsf__value-container {
    color: inherit;
  }

  .rsf__placeholder {
    color: $color-nevada;
  }

  .rsf__single-value {
    color: inherit;
  }

  .rsf__indicator {
    color: inherit;
    &:hover {
      color: inherit;
    }
    svg {
      color: inherit;
    }
  }

  .rsf__menu {
    overflow: hidden;
    border-radius: 0;
    z-index: 100;
    box-shadow: $box-shadow;
  }

  .rsf__menu-list {
    padding: 0;
  }

  .rsf__option {
    cursor: pointer;
    transition: 0.1s all linear;
    padding: 0 10px;
    @include text-cropping;
  }

  // Default
  &.var-default {
    .rsf__control {
      @include input;
      border-radius: 0;
      padding: 0;

      &--is-focused {
        background-color: $color-white;
        border-bottom-color: $color-picton-blue;
      }
    }

    .rsf__value-container {
      padding: 2px 7px;
    }

    &.rsf--is-disabled {
      .rsf__control {
        background-color: $color-grey-light-3;
      }
    }

    .rsf__option {
      color: $color-shark;
      background-color: $color-white;
      height: $field-height;
      line-height: $field-height;

      &--is-selected {
        background-color: lighten($color: $color-lochmara, $amount: 5%);
        color: $color-white;
      }

      &:hover {
        color: $color-white;
        background-color: $color-lochmara;
      }
    }
  }

  // Status
  &.var-status {
    .rsf__control {
      width: 270px;
      height: $status-field-height;
      min-height: $status-field-height;
      line-height: $status-field-height;
      font-size: 16px;
      border: 0;
      border-radius: calc(#{$status-field-height} / 2);
      font-weight: 500;

      &:hover {
        filter: saturate(0.9);
      }
    }

    .rsf__value-container {
      padding: 0 0 0 10px;
      top: -2px;
    }

    &.rs--is-disabled {
      .rsf__control {
        opacity: 0.7;
      }
    }

    .rsf__menu {
      border-radius: 8px;
      border: 0;
    }

    .rsf__option {
      height: $status-field-height;
      line-height: $status-field-height;
      font-weight: 500;
      font-size: 16px;

      &--is-selected {
        filter: saturate(0.7);
      }
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}
