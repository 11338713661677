@import "@assets/styles/variables.scss";

.navigation {
  position: absolute;
  left: -80vw;
  z-index: 10;
  background-color: $color-shark;
  transition: 0.1s all linear;
  top: $header-height;
  width: 80vw;
  height: calc(100vh - #{$header-height});

  &.active {
    transform: translateX(80vw);
  }

  .link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $color-catskill;
    padding: 15px;
    transition: 0.2s background-color linear;

    &.active {
      background-color: $color-venice;
    }

    &:hover {
      background-color: lighten($color: $color-venice, $amount: 6%);
    }
  }

  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}
