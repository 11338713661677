@import "@assets/styles/variables.scss";

.top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;

  .title {
    font-size: 30px;
    line-height: 40px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 15px 0 0;
    cursor: default;

    div {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        fill: $color-nevada;
      }
    }
  }
}

.tab {
  padding: $padding-default;
  background-color: $color-white;
}

.topPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;

  .filters {
    display: flex;
    align-items: center;
    gap: $padding-default;
    cursor: default;
  }
}

.table {
  thead {
    th {
      &:last-child {
        width: 200px;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      &:hover {
        td {
          color: $color-blue-primary;
        }
      }
    }
  }
}
