@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.container {
  display: flex;

  .box {
    flex: 1;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      border-right: 1px dashed $color-geyser;
    }

    .sub {
      flex: 1;
      padding: $padding-default;

      &:not(:last-child) {
        border-bottom: 1px dashed $color-geyser;
      }
    }
  }
}

.title {
  font-weight: 600;
  margin: 0 0 10px;
}

.row {
  display: flex;
  margin: 6px 0;
  gap: $padding-default;

  .label {
    width: 35%;
    font-size: 14px;
    color: $color-grey-dark-2;
    line-height: 18px;
    padding: 4px 0 0;
    flex-shrink: 0;
  }

  .value {
    width: 65%;
  }

  .list {
    padding: 0 0 0 15px;
    list-style: auto;
  }

  .actions {
    display: flex;
    gap: 10px;
  }

  :global {
    .select-field.var-default {
      .rsf__control {
        height: 24px;
        min-height: auto;
      }

      .rsf__value-container {
        padding: 0;
        line-height: 1;
      }

      .rsf__dropdown-indicator {
        padding: 4px;
      }

      .rsf__input-container {
        margin: 0;
      }
    }
  }
}

.input {
  border: 1px solid $color-picton-blue;
  outline: 0;
  padding: 0 5px;
  transition: 0.2s all linear;
  height: 24px;
}

.modal {
  width: 300px;
}
