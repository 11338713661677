@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

$cell-height: 40px;

.label {
  @include label;
}

.wrapper {
  position: relative;

  .trigger {
    @include input;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 10px;

    &.default {
      border-bottom: none;
      &.active {
        color: $color-lochmara;
      }
    }

    &.box {
      min-width: 200px;
      &.active {
        border-bottom-color: $color-picton-blue;
      }
    }

    .text {
      @include text-cropping;
    }

    .arrow {
      width: 10px;
      flex-shrink: 0;
    }
  }

  .popup {
    box-shadow: $box-shadow;
    position: absolute;
    z-index: 1;
    background-color: $color-catskill;
    color: $color-shark;
    padding: 0 10px 10px;
    top: calc(#{$field-height} + 10px);

    .dates {
      text-align: center;
      line-height: $field-height;
      font-size: 16px;
    }

    .container {
      display: flex;
      width: 500px;
      justify-content: space-between;

      .box {
        width: calc(50% - 3px);
        background-color: darken($color-catskill, 4%);
      }

      .yearSelector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: lighten($color: $color-lochmara, $amount: 4%);
        padding: 0 12px;
        height: $cell-height;
        color: $color-white;

        svg {
          opacity: 0.8;
          transition: 0.2s opacity linear;

          &:hover {
            opacity: 1;
          }
        }

        .year {
          font-size: 18px;
          cursor: default;
        }
      }

      .monthsWrapper {
        display: flex;
        flex-wrap: wrap;

        .month {
          width: 33.33%;
          padding: 0 8px;
          height: $cell-height;
          line-height: $cell-height;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          transition: 0.2s background-color linear;

          &.active {
            background-color: $color-picton-blue;
            color: $color-white;
          }

          &:hover {
            background-color: lighten($color-picton-blue, 4%);
            color: $color-white;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 5px;
      margin: 5px 0 0;

      button {
        width: 50%;
        height: 40px;
        font-size: 16px;

        &:only-child {
          width: 100%;
        }
      }
    }
  }
}
