@import "@assets/styles/variables.scss";

.modal {
  width: 600px;
  max-width: 80vw;
}

.note {
  font-size: 14px;
  line-height: 21px;
}

.subtitle {
  font-size: 14px;
  font-weight: 600;
  margin: 15px 0 10px;
  border-top: 2px solid $color-lochmara;
  padding: 5px 0 0;

  svg {
    fill: $color-lochmara;
    margin: 0 10px 0 0;
  }
}

.row {
  display: flex;
  gap: $padding-default;
  margin: 0 0 $padding-default;
}
