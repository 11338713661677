@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;

  .titleLine {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 5px 0 0;
  }

  .title {
    font-size: 30px;
    line-height: 40px;
    display: flex;
    gap: 10px;

    .number {
      font-weight: 500;
      flex-shrink: 0;
    }
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 15px 0 0;
  cursor: default;

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      fill: $color-nevada;
    }
  }
}

.container {
  display: flex;
  gap: $padding-default;

  .leftContent {
    width: calc(67% - 10px);

    > div {
      height: 100%;
    }
  }

  .rightContent {
    width: calc(33% - 10px);
    display: flex;
    gap: $padding-default;
    flex-direction: column;

    .chat {
      min-height: 400px;
    }
  }
}
