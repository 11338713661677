@import '@assets/styles/variables.scss';

.empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-geyser;
  flex-direction: column;
  gap: $padding-default;
  cursor: default;
  padding: $padding-default;

  svg {
    width: 74px;
    height: 74px;
  }
}
