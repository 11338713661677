@import "src/assets/styles/variables";

.container {
  color: $color-catskill;
  background-color: $color-shark;
  margin: 0 auto;
  max-width: 1200px;
  padding: calc(#{$padding-default} * 2);
  font-size: 16px;
  display: flex;

  .sidebar {
    width: 30%;
  }

  .title {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 $padding-default;
    cursor: default;
  }

  .content {
    width: 70%;
  }

  .rowTitle {
    font-size: 18px;
    line-height: 1;
    cursor: default;
  }

  .row {
    display: flex;
    gap: $padding-default;
    margin: $padding-default auto calc(#{$padding-default} * 2);

    .field {
      width: 50%;
      margin: 0;
    }
  }
}

// Components

.field {
  margin: $padding-default auto calc(#{$padding-default} * 2);
  position: relative;
  cursor: default;

  .label {
    color: $color-catskill;
    font-size: 14px;
    margin: 0 0 5px;
  }

  .description {
    color: $color-loblolly;
    font-size: 12px;
    margin: 0 0 5px;
  }

  .counter {
    text-align: right;
    font-size: 10px;

    &.overfilled {
      color: $color-torch;
    }
  }

  .error {
    color: $color-torch;
    font-size: 12px;
    position: absolute;
    bottom: -15px;
    text-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    line-height: 1.2;
  }
}

.navigation {
  display: flex;
  gap: $padding-default;
  margin: calc(#{$padding-default} * 2) auto $padding-default;

  .button {
    width: calc(50% - 10px);
    &.buttonNext,
    &.buttonConfirm {
      margin-left: auto;
    }
  }
}

.subtitle {
  display: flex;
  font-size: 22px;
  cursor: default;
  gap: calc(#{$padding-default} * 0.8);
  align-items: center;
  color: $color-catskill;
  margin: 0 0 $padding-default;

  .subtitleIcon {
    width: $field-height;
    height: $field-height;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-blue-dianne;
    flex-shrink: 0;

    svg {
      fill: $color-sky-light-1;
    }
  }
}

.counter {
  text-align: right;
  font-size: 10px;

  &.overfilled {
    color: $color-torch;
  }
}

@media (max-width: $breakpoint-lg) {
  .container {
    padding: 20px;
    display: block;

    .sidebar {
      width: 100%;
      margin: 0 0 20px;
    }

    .title {
      line-height: 1.2;
      margin: 0 0 20px;
      text-align: center;
    }

    .content {
      width: 100%;
    }

    .subtitle {
      font-size: 18px;
      line-height: 1.2;
    }

    .row {
      display: block;
      margin: 0;

      .field {
        width: 100%;
        margin: 0 0 20px;
      }
    }

    .field {
      margin: 0 0 20px;
    }

    .rowTitle {
      line-height: 1.2;
      margin: 0 0 10px;
      text-align: center;
    }
  }
}
