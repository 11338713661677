@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.field {
  position: relative;

  // Note: Can be button here?
  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 7px 10px;
    height: $field-height;
    transition: 0.2s all linear;
    background-color: $color-persian-green;
    color: $color-white;
    cursor: pointer;

    &:hover {
      background-color: lighten($color: $color-persian-green, $amount: 5%);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .input {
    display: none;
  }

  .file {
    @include input;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $padding-default 0 0;
    background-color: $color-botticelli;

    .text {
      @include text-cropping;
      cursor: default;
      font-size: 14px;
    }

    .fileOversized {
      font-style: italic;
      font-size: 12px;
      margin-left: auto;
      padding: 0 7px;
    }

    .fileDelete {
      right: 8px;
      width: 8px;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}

.error {
  @include input-error;
}
