@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.footer {
  padding: 5px 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #333;
  font-size: 14px;
  line-height: 1.4;
  font-family: "Open Sans", sans-serif;

  .icons {
    height: 42px;
  }

  .label {
    margin-right: 19px;
  }

  a,
  span {
    color: gray;

    &:hover {
      color: gray;
    }
  }

  @media (max-width: $breakpoint-lg) {
    padding: 0 10px;
  }
}
