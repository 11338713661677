@import "@assets/styles/variables.scss";

.container {
  display: flex;
  gap: $padding-default;
  align-items: flex-start;

  .button {
    min-width: 150px;
  }
}
