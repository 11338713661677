@import "@assets/styles/variables.scss";

.field {
  width: 100%;
}

.label {
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 600;
}
