@import "@assets/styles/variables.scss";

.tabs {
  :global .react-tabs__tab-list {
    display: inline-flex;
    width: 100%;

    :global .react-tabs__tab {
      cursor: pointer;
      outline: none;
      color: $color-grey-dark-2;
      background-color: $color-geyser;
      border-top: 3px solid $color-geyser;
      border-left: 1px solid $color-loblolly;
      height: $cell-height;
      padding: 15px;
      line-height: 23px;
      min-width: 10vw;

      &:first-child {
        border-left: none;
      }

      &:focus-visible {
        outline: none;
      }
    }

    :global .react-tabs__tab--disabled {
      pointer-events: none;
      color: $color-oslo;
      border-top-color: $color-loblolly;
      background-color: $color-loblolly;
    }

    :global .react-tabs__tab--selected {
      border-top-color: $color-picton-blue;
      border-left-color: $color-white;
      border-right-color: $color-white;
      color: $color-shark;
      background-color: $color-white;
      font-weight: 600;
      cursor: default;
    }
  }
}
