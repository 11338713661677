@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.field {
  position: relative;

  .input {
    @include input;
    resize: none;
    min-height: 100px;
    padding: 10px;
    line-height: 20px;
  }

  .error {
    @include input-error;
  }
}
