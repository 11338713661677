.tooltip {
  display: inline-block;
  max-width: 100%;
}

:global {
  .react-tooltip {
    z-index: 100;
    position: relative;
  }
}
