@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.form {
  position: sticky;
  top: 0;
  padding: 0 0 20px;

  .subtitle {
    font-size: 18px;
    font-weight: 600;
    padding: 18px;
    cursor: default;
  }

  .fields {
    border-top: 1px solid $color-geyser;
    border-bottom: 1px solid $color-geyser;
    padding: 0 20px 20px;

    .row {
      display: flex;
      gap: $padding-default;
      margin: 20px 0;

      > div {
        width: 50%;
        margin: 0;
      }
    }
  }

  .divider {
    margin: 20px 0;
    border: 1px solid $color-geyser;
  }

  .infoLabel {
    margin: 15px 0 5px;
    font-size: 14px;
  }

  .infoValue {
    color: $color-grey-dark-2;
    font-size: 14px;

    ol {
      padding: 0 0 0 20px;
    }
  }

  .button {
    margin: 20px 20px 0;
    width: calc(100% - 40px);
  }
}

.field {
  margin: 20px 0;
}
