@import "@assets/styles/variables.scss";

.navigation-stepper-container {
  .navigation-content {
    .navigation-element {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding-bottom: 32px;
      border-left: 2px solid #696f77;
      padding-left: 13px;
      transition: 1000ms linear;

      &.active {
        border-left-color: #35a5f3;
      }

      .navigation-label {
        font-size: 14px;
        color: #f2f4f8;

        &.clickable {
          cursor: pointer;
        }

        &.disabled {
          color: #a0a6ac;
        }
      }

      .navigation-icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          svg {
            fill: #35a5f3;
          }
        }

        &.disabled {
          svg {
            fill: #a0a6ac;
          }
        }

        &.completed {
          svg {
            fill: #42be65 !important;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .navigation-title {
    color: #f2f4f8;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 32px;
  }
}

@media (max-width: $breakpoint-lg) {
  .navigation-stepper-container {
    .navigation-content {
      .navigation-element {
        padding: 5px;
      }
    }
  }
}
