@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

$animation-duration: 0.5s;
$title-height: 40px;

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: $color-black-glass;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  cursor: default;

  .modal {
    color: $color-shark;
    background-color: $color-catskill;
    animation: opacity-transition $animation-duration;

    .title {
      font-size: 20px;
      line-height: 1;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include text-cropping;

      .closeButton {
        opacity: 0.7;
        transition: 0.2s opacity linear;

        &:hover {
          opacity: 1;
        }

        .closeIcon {
          width: 14px;
          height: 14px;
          fill: $color-shark;
        }
      }
    }

    .content {
      padding: 0 $padding-default $padding-default;
      min-height: 100px;
      font-size: 18px;

      b {
        font-weight: 700;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: $padding-default;
      gap: $padding-default;
      border-top: 1px solid $color-geyser;

      span[data-tooltip-id] {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }
}

.modal {
  min-width: 500px;
}

@keyframes opacity-transition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
