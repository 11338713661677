@import "src/assets/styles/variables";

$margin: 20px;

.wrapper {
  min-height: $viewport-height;
  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    color: $color-catskill;
    background-color: $color-shark;
    width: 35%;
    padding: calc(#{$margin} * 1.5);
  }

  .icon {
    width: 40px;
    height: 40px;
    fill: $color-green-grass;
    margin: 0 auto $margin;
    display: block;
  }

  .title {
    font-size: 20px;
    text-align: center;
    margin: $margin 0;

    b {
      font-weight: 700;
    }
  }

  .text {
    margin: 0 0 10px;
  }

  .list {
    list-style: auto;
    padding: 0 0 0 $margin;

    b {
      font-weight: 600;
    }
  }

  .button {
    margin: $margin 0 0;
    width: 100%;
  }
}

@media (max-width: $breakpoint-lg) {
  .wrapper {
    .box {
      width: 100%;
      margin: 20px;
    }
  }
}
