@import "@assets/styles/variables.scss";

.label {
  flex-shrink: 0;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  font-weight: 600;
  padding: 0 15px;
  text-align: center;
  font-size: 16px;

  &.green {
    color: $color-jewel;
    background-color: $color-pastel-green;
  }

  &.grey {
    color: $color-oslo;
    background-color: $color-silver;
  }

  &.orange {
    color: $color-pizza;
    background-color: $color-carry;
  }

  &.red {
    color: $color-white;
    background-color: $color-alizarin;
  }

  &.blue {
    color: $color-venice;
    background-color: $color-hawkes-blue;
  }

  svg {
    fill: currentColor;
  }
}
