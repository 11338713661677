@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.calendars:global(.react-datepicker) {
  border: none;
  border-radius: 0;
}

.calendars {
  :global {
    .react-datepicker__month-container {
      box-shadow: none;

      &:last-child {
        border-left: 3px solid $color-catskill;
      }
    }

    .react-datepicker__header {
      border-bottom: none;
      font-size: 18px;
      border-radius: 0;
      background-color: $color-white;
      color: $color-shark;
      padding: 15px 8px 8px;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      color: $color-black;
      width: 45px;
      line-height: 45px;
      text-align: center;
      margin: 0;
      font-size: 16px;
      outline: 0;
      border-radius: 0;
      transition: 0.2s all linear;

      &:hover {
        border-radius: 0;
      }
    }

    .react-datepicker__day--keyboard-selected {
      background-color: $color-white;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .react-datepicker__day-name {
      color: inherit;
      cursor: default;
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected {
      background-color: $color-picton-blue;
      color: $color-white;

      &:hover {
        border-radius: 0;
        background-color: lighten($color-picton-blue, 8%);
      }
    }

    .react-datepicker__day--disabled {
      color: $color-geyser;
      background-color: $color-white;
    }

    .react-datepicker__current-month {
      margin: 0;
      line-height: 30px;
      font-size: 20px;
      font-weight: 500;
      color: inherit;
      cursor: default;
    }

    .react-datepicker__navigation--next {
      display: none;
    }

    .react-datepicker__navigation--previous {
      display: none;
    }
  }
}
