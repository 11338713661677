@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.container {
  padding: $padding-default;
}

.counters {
  display: flex;
  margin: 0 0 40px;
  gap: 20px;

  .counter {
    display: flex;
    gap: 10px;
    align-items: center;

    .digit {
      font-size: 44px;
      line-height: 1;
      font-weight: 300;
    }
    .text {
      color: $color-grey-dark-2;
      line-height: 1.2;
      cursor: default;
    }
  }
}

.part {
  display: flex;
  gap: 20px;
  align-items: flex-end;

  .time {
    display: flex;
    flex-direction: column;
    align-items: center;

    .round {
      margin: 8px 0;
      width: 20px;
      height: 20px;
      border: 1px dashed $color-grey-chateau;
      border-radius: 50%;
    }

    .line {
      height: 60px;
      width: 1px;
      border: 1px dashed $color-grey-chateau;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 20px;

    .icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $color-catskill;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-nevada;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .text {
      line-height: 14px;
      font-size: 12px;

      .label {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .button {
    margin: 0 0 10px auto;
  }

  &:first-child {
    .time {
      .line {
        display: none;
      }
    }
  }

  &.finished {
    .time {
      .line {
        border: 1px solid $color-jewel;
      }
    }
  }

  &.finished + .part {
    .time {
      .round {
        border: 1px solid $color-lochmara;
        background-color: $color-lochmara;
      }
    }
  }
}

.successIcon {
  margin: 8px 0;
  width: 20px;
  height: 20px;
  fill: $color-jewel;
}
