@import "@assets/styles/variables.scss";

.top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;

  .title {
    font-size: 30px;
    line-height: 40px;
  }

  .info {
    display: flex;
    align-items: center;
    margin: 15px 0 0;
    cursor: default;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      fill: $color-nevada;
    }
  }
}

.tab {
  padding: $padding-default;
  background-color: $color-white;
}

.topPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;

  .filters {
    display: flex;
    align-items: center;
    gap: $padding-default;
    cursor: default;
  }

  .search {
    width: 285px;
  }
}

:global(.ant-table-wrapper).table {
  thead {
    th {
      text-align: center;

      &:nth-child(1),
      &:nth-child(2) {
        text-align: left;
      }
    }
  }
  tbody {
    td {
      text-align: center;

      &:nth-child(1),
      &:nth-child(2) {
        text-align: left;
      }

      a {
        margin: 0 auto;
      }
    }
  }
}
