@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.field {
  position: relative;

  .input {
    @include input;
  }

  .error {
    @include input-error;
  }
}
