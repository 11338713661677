@mixin text-cropping {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin field {
  line-height: 20px;
  padding: 10px;
}

@mixin btn-color($color) {
  background-color: $color;

  &:hover {
    background-color: lighten($color, 10%);
  }
}

@mixin img-centered($height) {
  height: $height;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin label {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 8px;
  cursor: default;
}

@mixin input {
  height: $field-height;
  width: 100%;
  padding: 5px 10px;
  color: $color-shark;
  border: 0;
  outline: 0;
  font-size: 16px;
  transition: 0.2s all linear;
  background-color: $color-white;
  border-bottom: 1px solid $color-grey-chateau;

  &.inputError {
    background-color: $color-pippin;
    border-bottom-color: $color-alizarin;
  }

  &:disabled {
    background-color: $color-geyser;
    color: $color-grey-dark-2;
  }

  &::placeholder {
    color: $color-nevada;
  }

  &:focus {
    border-bottom-color: $color-picton-blue;
  }
}

@mixin input-error {
  position: absolute;
  line-height: 1;
  font-size: 12px;
  color: $color-alizarin;
}

@mixin widget-title {
  font-size: 18px;
  font-weight: 600;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin rotation {
  animation: 1s linear 0s normal none infinite running rot;
  -webkit-animation: 1s linear 0s normal none infinite running rot;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
