@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.wrapper {
  padding: $padding-default;

  .title {
    @include widget-title;
  }

  .chart {
    display: flex;
    align-items: center;
    margin: 40px 0 0;

    .doughnut {
      width: 300px;
      height: 300px;
      flex-shrink: 0;
      position: relative;

      canvas {
        position: relative;
        z-index: 100;
      }
    }

    .labels {
      display: flex;
      padding: 0 0 0 60px;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: column;

      .label {
        width: 100%;
        cursor: default;
        line-height: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 10px;

        .square {
          flex-shrink: 0;
          width: 16px;
          height: 16px;
        }

        span {
          color: $color-grey-dark-2;
          font-size: 12px;
        }
      }
    }
  }
}
