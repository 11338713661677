@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.option {
  display: flex;
  align-items: center;
  gap: $padding-default;
  margin: 0 0 10px;

  .text {
    width: 65%;
    border: 0;
    outline: 0;
    color: $color-shark;
    height: $field-height;
    font-size: 16px;
    padding: 5px 10px;
  }

  .dropdown {
    width: 35%;

    :global {
      .rsf__menu-list {
        max-height: 200px;
      }
    }
  }

  .button {
    width: $padding-default;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.note {
  font-size: 14px;
}

.field {
  position: relative;

  .error {
    @include input-error;
  }
}

// TO DO: Check this after merging response styles
@media (max-width: $breakpoint-lg) {
  .option {
    flex-direction: column;
    position: relative;

    .text {
      width: 100%;
    }

    .dropdown {
      width: 100%;
    }

    .button {
      background: $color-lynch;
      width: 100%;
      height: $field-height;
      margin: 0 0 20px;
    }
  }
}
