@import "@assets/styles/variables.scss";

// Text
.text-section {
  width: 50%;

  .title {
    margin: 20px 0;
    font-size: 22px;
  }
}

// Common structure
.section-container {
  margin: 20px 0;

  // H1
  .label-component {
    color: $color-white;
    font-size: 50px;
    line-height: 72px;
    width: 50%;
    padding: 13vh 0 15px;
  }

  .section-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    // Card
    .card-container {
      background-blend-mode: multiply;
      background-size: cover;
      color: $color-white;
      border-radius: $border-radius-small;
      background-color: $color-venice;
      width: calc(50% - 25px);

      .card-content {
        padding: 10px 20px 20px 40px;

        .card-text {
          > * {
            color: $color-white;
          }
        }

        .link {
          margin-left: auto;
          cursor: pointer;

          svg {
            fill: $color-white;
            width: 20px;
            height: 20px;
            margin-left: 5px;
            transform: rotate(-90deg);
          }
        }
      }

      .card-title {
        width: 100%;
        padding: 15px 10px;
        border-radius: $border-radius-small $border-radius-small 0 0;
        background-color: #00000033;

        p {
          margin-left: 10px;
          font-size: 18px;
        }
      }
    }

    // Buttons
    .buttons-section {
      margin: 20px 0;
      width: 100%;

      .section-label {
        margin-bottom: 20px;
        font-size: 12px;
        display: flex;
        white-space: nowrap;

        hr {
          margin-left: 20px;
          height: 1px;
          align-self: end;
          width: 100%;
          border-bottom: 1px;
          border-right: 0;
          border-left: 0;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        .button {
          padding: 0 20px;
          line-height: 40px;
          height: 40px;
          cursor: pointer;
          color: $color-shark;
          display: flex;
          align-items: center;
          gap: 10px;
          transition: 0.2s filter linear;

          &:hover {
            filter: brightness(110%);
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .text-section {
    width: 100%;
    text-align: center;
  }

  .section-container {
    .label-component {
      width: 100%;
      font-size: 28px;
      line-height: 1.4;
      text-align: center;
      padding: 10vw 0;
    }

    .section-content .buttons-section .buttons {
      justify-content: center;
    }

    .section-content .card-container {
      width: 100%;
    }
  }
}
