@import "@assets/styles/variables.scss";

.top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;

  .title {
    font-size: 30px;
    line-height: 40px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 15px 0 0;
    cursor: default;

    div {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        fill: $color-nevada;
      }
    }
  }
}

.tab {
  padding: $padding-default;
  background-color: $color-white;
}

.topPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;

  .filters {
    display: flex;
    align-items: center;
    gap: $padding-default;
    cursor: default;
  }

  .search {
    width: 285px;
  }
}

:global(.ant-table-wrapper).table {
  thead {
    th {
      text-align: center;

      &:nth-child(1),
      &:nth-child(2),
      &:last-child {
        text-align: left;
      }

      &:last-child {
        width: 180px;
        padding-left: 18px;
      }
    }
  }
  tbody {
    td {
      text-align: center;

      &:nth-child(1),
      &:nth-child(2),
      &:last-child {
        text-align: left;
      }

      a {
        margin: 0 auto;
      }
    }
  }
}

.buttons {
  display: flex;
  gap: $padding-default;
}

.choices {
  display: flex;
  gap: $padding-default;

  .choice {
    border: 1px solid $color-geyser;
    background-color: $color-white;
    padding: $padding-default;
    transition: 0.2s all linear;
    color: $color-nevada;
    width: 250px;
    cursor: pointer;

    &:hover {
      border-color: $color-picton-blue;
      background-color: $color-blue-light;
    }

    .title {
      font-size: 14px;
    }

    .description {
      font-size: 18px;
      color: $color-shark;
      margin: 5px 0 20px;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .plus {
        font-size: 30px;
        color: $color-lochmara;
      }
    }
  }
}
