@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.wrapper {
  padding: $padding-default;
}

.title {
  @include widget-title;
}
