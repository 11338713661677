@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

$timeline-width: 52px;
$cell-height: 32px;

.wrapper {
  background-color: $color-white;
  color: $color-shark;
  font-size: 14px;
  flex-direction: column;
  display: flex;
  width: 100%;

  .navigation {
    min-height: 50px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid $color-geyser;

    .info {
      font-size: 20px;
      b {
        font-weight: 600;
      }
    }

    .legend {
      margin-left: auto;
      display: flex;
      gap: 10px;

      .check {
        height: 30px;
        width: 80px;
        border-left: 4px solid transparent;
        font-size: 12px;
        font-weight: 600;
        padding: 3px 5px;
        cursor: default;

        &.in {
          background-color: $color-battery;
          border-color: $color-sycamore;
        }

        &.out {
          background-color: $color-tutu;
          border-color: $color-bluch;
        }
      }
    }
  }

  .labels {
    padding: 0 0 0 $timeline-width;
    display: flex;
    line-height: $cell-height;

    .label {
      flex-grow: 1;
      text-align: center;
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .active {
        background-color: $color-botticelli;
        color: $color-venice;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
      }
    }
  }

  .frame {
    display: flex;
    align-items: stretch;
    border-top: 1px solid $color-geyser;
    border-bottom: 1px solid $color-geyser;
    overflow: hidden;

    .timeline {
      width: $timeline-width;
      flex-shrink: 0;
      border-right: 1px solid $color-geyser;
    }

    .container {
      display: flex;
      width: 100%;

      .day {
        flex-grow: 1;
        position: relative;
        z-index: 1;

        &:not(:last-child) {
          border-right: 1px solid $color-geyser;
        }

        &.disabled {
          background-color: $color-catskill;
        }

        .event {
          position: absolute;
          top: 0;
          z-index: 5;
          background-color: $color-white;
          width: 100%;
          transition: 0.2s all linear;
          overflow: hidden;
          cursor: default;
          box-shadow: $box-shadow-tiny;

          &.halfWidth {
            width: 50%;
          }

          &:hover {
            width: 100%;
            z-index: 7;
            box-shadow: $box-shadow;
          }
        }
      }
    }

    .times {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .time {
        text-align: center;
        line-height: $cell-height;
        height: $cell-height;
        cursor: default;

        &:not(:last-child) {
          border-bottom: 1px solid $color-geyser;
        }
      }

      .timeStart {
        &:not(:last-child) {
          border-bottom: 1px dashed $color-geyser;
        }
      }
    }
  }
}
