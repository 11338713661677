@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.container {
  min-height: $viewport-height;
  display: flex;
  justify-content: space-between;

  .content {
    padding: 0 7vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - #{$aside-width});
  }
}

.title {
  font-size: 60px;
  line-height: 75px;
  width: 100%;
  cursor: default;

  b {
    font-weight: 500;
  }
}

.place {
  margin: 20px 0 40px;
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    fill: $color-catskill;
  }
}

.blocks {
  display: flex;
  width: 100%;
  gap: 30px;

  .block {
    width: 33%;
    padding: $padding-default;
    background-color: $color-catskill;
    color: $color-grey-dark-2;
    font-size: 14px;
    line-height: 21px;
    cursor: default;
    display: flex;
    flex-direction: column;

    .label {
      color: $color-shark;
      font-size: 20px;
    }

    .bottom {
      margin: auto 0 0;

      .button {
        width: 100%;
        margin: 10px 0 0;
      }
    }

    b {
      color: $color-shark;
      font-weight: 600;
    }
  }
}

.sidebar {
  background-color: $color-shark-trans-light;
  width: $aside-width;
  height: $viewport-height;
  flex-shrink: 0;
  overflow-y: auto;
  padding: $padding-default;

  .sidebarTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .infoBlock {
    padding: $padding-default 0;
    font-size: 14px;
    color: $color-loblolly;
    border-bottom: 1px solid $color-grey-middle-3;

    .subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px;
      font-size: 16px;
      color: $color-white;
      line-height: 1;
    }
  }

  .buttonView {
    font-size: 14px;
    margin: 5px 0 0;
  }
}
