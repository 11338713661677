@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

$margin: 20px;
$input-height: 40px;
$field-font-size: 16px;

.section {
  padding: $margin $margin 0;

  .title {
    color: $color-catskill;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-catskill;
    cursor: default;
    margin: 0 0 calc(#{$margin} * 1.5);

    .titleButton {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;

      svg {
        fill: $color-catskill;
      }

      &:hover {
        color: $color-white;
      }
    }
  }

  .row {
    display: flex;
    margin: $margin 0;
    line-height: 16px;
    align-items: center;
    gap: $padding-default;

    .label {
      width: 30%;
      color: $color-loblolly;
      font-size: 14px;
      padding: 0 20px 0 0;
      flex-shrink: 0;
    }

    .value {
      @include text-cropping;
      width: 70%;
      color: $color-catskill;
      font-size: 16px;
      line-height: 20px;
    }

    .list {
      padding: 0 0 0 $padding-default;
      list-style: auto;

      li {
        margin: 0 0 5px;
      }
    }
  }
}

.remarksField {
  margin: calc(#{$margin} * 2) 0 0;
}

.terms {
  padding: $margin $margin 0;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;

  .link {
    color: $color-loblolly;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .section {
    .row {
      display: block;

      .label {
        width: 100%;
      }

      .value {
        width: 100%;
      }
    }
  }

  .terms {
    display: block;
  }
}
