@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.wrapper {
  padding: $padding-default;
}

.title {
  @include widget-title;
}

.line {
  display: flex;
  margin: 40px 0 $padding-default;

  .block {
    height: 16px;
    position: relative;

    .value {
      font-size: 12px;
      color: $color-grey-dark-2;
      position: absolute;
      top: 0;
      top: -20px;
      width: 100%;
      line-height: 20px;
      text-align: center;
      cursor: default;
    }
  }
}

.labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $padding-default;

  .label {
    display: flex;
    align-items: center;

    .dot {
      width: 16px;
      height: 16px;
    }

    .text {
      padding: 0 10px;
      line-height: 16px;
      cursor: default;
    }
  }
}
