@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.field {
  display: block;
  margin: 10px auto;

  .input {
    display: none;
  }

  .item {
    width: 100%;
    height: $field-height;
    border: 2px solid $color-oslo;
    background-color: $color-grey-dark-2;
    color: $color-catskill;
    padding: 7px 10px;
    cursor: pointer;
    transition: 0.2s all linear;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: lighten($color: $color-grey-dark-2, $amount: 4%);
    }

    svg {
      fill: $color-chateau;
      opacity: 0;
      transition: 0.2s opacity linear;
    }
  }

  .input:checked + .item {
    background-color: $color-nandor;
    border-color: $color-chateau;

    svg {
      opacity: 1;
    }
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $padding-default;

  .field {
    margin: 0;
    width: 100%;
  }
}

.wrapper {
  position: relative;

  .error {
    @include input-error;
    bottom: -12px;
  }
}

@media (max-width: $breakpoint-lg) {
  .row {
    grid-template-columns: repeat(1, 1fr);
  }
}
