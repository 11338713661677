@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.container {
  display: flex;

  .box {
    flex: 1;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      border-right: 1px dashed $color-geyser;
    }

    .sub {
      flex: 1;
      padding: $padding-default;

      &:not(:last-child) {
        border-bottom: 1px dashed $color-geyser;
      }
    }
  }
}

.title {
  font-weight: 600;
  margin: 0 0 10px;
}

.row {
  display: flex;
  margin: 6px 0;
  gap: $padding-default;

  .label {
    width: 35%;
    font-size: 14px;
    color: $color-grey-dark-2;
    line-height: 18px;
    padding: 4px 0 0;
    flex-shrink: 0;
  }

  .value {
    width: 65%;
  }

  .list {
    padding: 0 0 0 15px;
    list-style: auto;
  }
}
