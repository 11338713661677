@import "@assets/styles/variables.scss";

.top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 $padding-default;

  .title {
    font-size: 30px;
    line-height: 40px;
  }

  .info {
    display: flex;
    align-items: center;
    margin: 15px 0 0;
    cursor: default;
    gap: 8px;

    svg {
      fill: $color-nevada;
    }
  }
}

.tab {
  padding: $padding-default;
  background-color: $color-white;
}

.topPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;

  .filters {
    display: flex;
    align-items: center;
    gap: $padding-default;
    cursor: default;
  }
}

@mixin cell {
  &:last-child {
    width: 200px;
  }
  &:nth-last-child(2) {
    text-align: center;
  }
}

.table {
  thead {
    th {
      @include cell;
    }
  }

  tbody {
    tr {
      cursor: pointer;
      &:hover {
        td {
          color: $color-blue-primary;
        }
      }
      td {
        @include cell;
      }

      &.pendingRow {
        td {
          background-color: $color-pearl-lusta;
        }

        &:hover {
          td {
            background-color: darken($color-pearl-lusta, 4%);
            color: inherit;
          }
        }
      }
    }
  }
}

.statusCell {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tipContent {
  padding: 5px;
  text-align: left;
  line-height: 1.2;
  width: 220px;

  .tipTitle {
    font-weight: 700;
    // TO DO: Make tip with adaptive styles
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin: 0 0 5px;

    .date {
      color: $color-grey-dark-2;
      font-size: 14px;
    }
  }
}
