@import "@assets/styles/variables.scss";

.box {
  border-left: 4px solid $color-corn;
  background-color: $color-grey-dark-2;
  color: $color-white;
  padding: $padding-default;
  width: 500px;

  .buttons {
    display: flex;
    gap: $padding-default;
    margin: $padding-default 0 0;

    button,
    a {
      width: 100%;
    }
  }
}
