@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.wrapper {
  position: relative;
  color: $color-shark;

  .icon {
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 12px;
  }

  .input {
    @include input;
    padding: 5px 35px 5px 32px;
  }

  .button {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 12px;
    opacity: 0.7;
    transition: 0.2s opacity linear;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 14px;
      height: 14px;
      fill: $color-shark;
    }
  }
}
