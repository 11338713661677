@import "@assets/styles/variables.scss";

.container {
  padding: 120px 0 0 40px;
  max-width: 500px;
  color: $color-catskill;

  .title {
    font-size: 36px;
    line-height: 46px;
    color: $color-catskill;
  }

  hr {
    height: 1px;
    background-color: $color-catskill;
    border: 0;
    margin: 35px 0;
  }

  p {
    font-size: 18px;
    line-height: 27px;
  }

  .buttons {
    display: flex;
    gap: $padding-default;
    margin-top: 45px;

    .button {
      min-width: 170px;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .container {
    width: 100%;
    padding: 0;
    text-align: center;

    .buttons {
      justify-content: center;

      .button {
        min-width: auto;
      }
    }
  }
}
