@import "@assets/styles/variables.scss";

.table {
  width: 50vw;
  max-height: 50vh;
  overflow-y: auto;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
