@import "@assets/styles/variables.scss";

:global .Toastify__toast-container {
  padding: 0;
  min-width: 500px;

  &--top-center {
    top: 0;
  }
}

:global .Toastify__toast {
  padding: 0;
  border-radius: $border-radius-small;
  box-shadow: none;
  cursor: default;
}

:global .Toastify__toast-body {
  width: 100%;
  padding: $padding-default;
  text-align: center;
  height: $header-height;
  color: $color-white;
  font-size: 18px;
}

:global .Toastify__toast-icon {
  margin: 0;
}

:global .Toastify__toast--dark {
  background-color: $color-black;
}

:global .Toastify__toast--default {
  background-color: $color-black;
}

:global .Toastify__toast--info {
  background-color: $color-venice;
}

:global .Toastify__toast--success {
  background-color: $color-green-grass;
}

:global .Toastify__toast--warning {
  background-color: $color-yellow;
  color: $color-shark;
}

:global .Toastify__toast--error {
  background-color: $color-red-dark-1;
}
