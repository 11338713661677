@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.wrapper {
  position: relative;
}

.field {
  height: $field-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 16px;
  transition: 0.2s all linear;

  &.active {
    color: $color-lochmara;
  }

  &:hover {
    color: $color-blue-primary;
  }

  .value {
    @include text-cropping;
    margin: 0 10px;
  }

  .arrow {
    margin: 0 10px 0 auto;
    width: 10px;
    flex-shrink: 0;
  }
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}

.dropdownBox {
  position: absolute;
  z-index: 100;
  top: calc(#{$field-height} + 10px);
  padding: 10px;
  width: 280px;
  font-size: 16px;
  box-shadow: $box-shadow;
  background-color: $color-catskill;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  cursor: default;
}

.checkbox {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: inherit;
    cursor: pointer;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 2px;
      background-color: $color-white;
      border-radius: $border-radius-small;
      border: 1px solid $color-geyser;
    }

    span {
      @include text-cropping;
    }
  }

  .input:checked + .label::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 75% 75%;
    background-color: $color-lochmara;
  }
}

.section {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $field-height;

    .arrow {
      margin: 0 4px 0 auto;
    }
  }

  .body {
    padding: 0 0 0 20px;
  }
}

.applyButton {
  width: 100%;
  margin: 10px auto 0;
}

.clearButton {
  background: none;
  color: $color-lochmara;
  margin: 0 auto;

  &:hover {
    background: none;
    color: $color-lochmara;
    text-decoration: underline;
  }
}
