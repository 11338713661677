@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.container {
  min-height: $viewport-height;
  padding: 0 7vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-size: 60px;
  line-height: 75px;
  width: 100%;
  cursor: default;

  b {
    font-weight: 500;
  }
}

.place {
  margin: 20px 0 40px;
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    fill: $color-catskill;
  }

  :global {
    .select-field.var-default {
      .rsf__control {
        font-size: 20px;
        background: none;
        color: $color-white;
        border: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.blocks {
  display: flex;
  width: 100%;
  gap: 30px;

  .block {
    width: 25%;
    padding: $padding-default;
    background-color: $color-catskill;
    color: $color-grey-dark-2;
    font-size: 14px;
    line-height: 21px;
    cursor: default;
    display: flex;
    flex-direction: column;

    .label {
      color: $color-shark;
      font-size: 20px;
    }

    .description {
      margin: 10px 0 20px;
    }

    .bottom {
      margin: auto 0 0;

      .button {
        width: 100%;
        margin: 10px 0 0;
      }
    }

    b {
      color: $color-shark;
      font-weight: 600;
    }
  }
}
