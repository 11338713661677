@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.button {
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  height: $button-height;
  padding: 0 20px;
  text-decoration: none;
  transition: 0.2s all linear;
  font-size: 16px;
  color: $color-catskill;

  &:hover {
    color: $color-catskill;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default;
  }

  svg {
    width: $icon-size;
    height: $icon-size;
  }
}

.btn-icon {
  background-color: transparent;
  opacity: 0.7;
  padding: 0;
  height: auto;
  line-height: 1;
  color: inherit;

  &:hover {
    color: inherit;
    opacity: 1;
  }

  svg {
    width: $icon-size;
    height: $icon-size;
    fill: currentColor;
  }
}

.btn-transparent {
  background-color: transparent;
  color: $color-white;
  border: 1px solid $color-white;

  &:hover {
    background-color: $color-grey-middle-5;
  }
}

.btn-transparent-negative {
  background-color: transparent;
  color: $color-shark;
  border: 1px solid $color-shark;

  &:hover {
    background-color: $color-geyser;
    color: $color-shark;
  }
}

.btn-text {
  background-color: transparent;
  padding: 0;
  height: auto;
  line-height: 1;
  color: inherit;

  &:hover {
    color: $color-blue-primary;
  }
}

a.btn-text {
  width: fit-content;
}

// Color variants
$colors: (
  "lochmara": $color-lochmara,
  "lynch": $color-lynch,
  "forest": $color-forest,
  "supernova": $color-supernova,
  "white": $color-white,
  "kumera": $color-kumera,
  "alizarin": $color-alizarin,
  "space": $color-outer-space,
  "paarl": $color-paarl,
  "geyser": $color-geyser
);

@each $name, $color in $colors {
  .btn-#{$name} {
    @include btn-color($color);
  }
}

.btn-supernova,
.btn-geyser,
.btn-white {
  color: $color-shark;

  &:hover {
    color: $color-shark;
  }

  svg {
    fill: $color-shark;
  }
}

.btn-white {
  &:hover {
    background-color: $color-blue-light;
  }
}
