@import "@assets/styles/variables.scss";

.navigation {
  height: $nav-height;
  display: flex;
  gap: $padding-default;
  padding: 0 $padding-default;
  background-color: $color-shark-transparent;

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: $color-catskill;
    padding: 0 $padding-default;
    transition: 0.2s background-color linear;

    &.active {
      background-color: $color-venice;
    }

    &:hover {
      background-color: lighten($color: $color-venice, $amount: 6%);
    }
  }

  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}
