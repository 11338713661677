@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.topLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 10px 0 20px;
  font-size: 30px;
  line-height: 40px;
}

.box {
  border-left: 4px solid $color-corn;
  background-color: $color-grey-dark-2;
  color: $color-white;
  padding: 10px $padding-default;
  align-items: center;
  display: flex;
  gap: $padding-default;
  margin: 0 0 $padding-default;

  .dates {
    font-weight: 500;
  }
}

.container {
  display: flex;
  gap: 20px;

  .content {
    width: 75%;
  }

  .aside {
    width: 25%;
    border-left: 1px solid $color-geyser;
    margin: -20px -20px -20px 0;
  }
}
