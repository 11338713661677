@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.field {
  position: relative;

  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__header--time--only {
      display: none;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      height: auto !important;
      padding: 7px 5px !important;
    }

    .react-datepicker {
      font-size: 16px;
      color: $color-white;
      border: none;
      border-radius: $border-radius-small;

      .react-datepicker {
        &__month-container {
          box-shadow: 3px 3px 10px 0 rgba(30, 42, 54, 0.6);
        }

        &__header {
          border-bottom: none;
          border-top-left-radius: $border-radius-small;
          background-color: $color-lochmara;
          padding: 8px;
          font-size: 18px;
          color: $color-white;
        }

        &__navigation--next {
          border-left-color: $color-white;
        }

        &__navigation--previous {
          border-right-color: $color-white;
        }

        &__day,
        &__day-name {
          color: $color-black;
          width: 45px;
          line-height: 45px;
          text-align: center;
          margin: 0;
          font-size: 16px;
          outline: 0;
          border-radius: 0;
          transition: 0.2s all linear;
        }

        &__day--keyboard-selected {
          background-color: $color-white;

          &:hover {
            background-color: #f0f0f0;
          }
        }

        &__day-name {
          color: inherit;
          cursor: default;
        }

        &__day--selected,
        &__time-list-item--selected {
          background-color: $color-picton-blue;
          color: $color-white;

          &:hover {
            background-color: lighten($color-picton-blue, 8%);
          }
        }

        &__day--disabled {
          color: $color-nevada;
          background-color: $color-silver;
        }

        &__current-month {
          margin-top: 0;
          margin-bottom: 10px;
          line-height: 30px;
          font-size: 20px;
          font-weight: 500;
          color: inherit;
          cursor: default;
        }

        &__navigation {
          transform: scale(1.5);
          outline: none;
          top: 8px;
        }

        &__year-select {
          width: 80px;
          height: 30px;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          outline: none;
          border: none;
          color: $color-shark;
        }

        &__time-list-item {
          color: $color-grey-dark-2;
          font-size: 16px;
        }

        &__time-box {
          box-shadow: $box-shadow;
        }
      }
    }

    .react-datepicker__aria-live {
      display: none;
    }

    .react-datepicker__navigation-icon {
      opacity: 0.8;
      transition: 0.2s opacity linear;

      &::before {
        border-color: $color-white;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .input {
    @include input;
  }

  .error {
    @include input-error;
  }

  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: $icon-size;
    height: $icon-size;
  }

  &.inline {
    :global {
      .react-datepicker {
        border-radius: 0;

        .react-datepicker {
          &__month-container {
            box-shadow: none;
          }

          &__header {
            border-radius: 0;
            background-color: $color-white;
            color: $color-shark;
          }

          &__day--disabled {
            color: $color-geyser;
            background-color: $color-white;
          }

          &__navigation--next {
            border-left-color: $color-shark;
          }

          &__navigation--previous {
            border-right-color: $color-shark;
          }
        }
      }

      .react-datepicker__navigation-icon {
        &::before {
          border-color: $color-shark;
        }
      }
    }
  }
}
