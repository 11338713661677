@import "@assets/styles/variables.scss";

.note {
  font-weight: 500;
  line-height: 22px;
  margin: 0 0 20px;
  text-align: center;
  line-height: 1;
}

.icon {
  width: 80px;
  height: 80px;
  display: block;
  margin: $padding-default auto;
  fill: $color-nevada;
}
