@import '@assets/styles/variables.scss';

.table {
  :global {
    .ant-table {
      color: inherit;
      line-height: inherit;
      font-size: inherit;
      background: transparent;
      border-radius: 0;
    }

    .ant-table-cell {
      border-color: $color-geyser;
      padding: 0 12px;
      height: $cell-height;
      vertical-align: middle;
      transition: 0.2s all linear;
      max-width: 40vw;
    }

    .ant-table-thead {
      .ant-table-cell {
        font-weight: normal;
        background-color: $color-geyser;
        border: 0;

        &:before {
          display: none;
        }
      }
    }

    .ant-table-summary {
      .ant-table-cell {
        background-color: $color-geyser;
      }
    }
  }
}
