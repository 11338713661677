$color-black: #000;
$color-white: #fff;

$color-grey-dark-1: #3f424e;
$color-grey-dark-2: #434a4e;
$color-grey-dark-3: #1e2a36;
$color-grey-dark-4: #262829;
$color-grey-dark-5: #171717;
$color-grey-dark-6: #161616;
$color-grey-light-1: #c5d3e2;
$color-grey-light-2: #e0e8ed;
$color-grey-light-3: #b8bfc4;
$color-grey-light-4: #ccc;
$color-grey-middle-1: #6e7c8b;
$color-grey-middle-2: #333536;
$color-grey-middle-3: #323536;
$color-grey-middle-4: #4e4e4e;
$color-grey-middle-5: #2a2d34;

$color-blue-primary: #0288ff;
$color-blue-secondary: #3a89f7;
$color-blue-dark-1: #095d93;
$color-blue-dark-2: #075287;
$color-blue-light: #f0f7fd;
$color-green-middle: #417505;
$color-green-grass: #0dac26;
$color-cyan: #0adbbb;
$color-red-dark-1: #b3262b;
$color-red-light-1: #ff6666;
$color-sky-light-1: #52a5ff;
$color-sky-light-2: #0a93db;
$color-blue-dark-3: #0a3adb;
$color-yellow: #eac810;

$color-alizarin: #d7232c;
$color-battery: #fffbeb;
$color-black-glass: #000000a1;
$color-bluch: #b94177;
$color-blue-dianne: #223a4b;
$color-blue-lagoon: #007180;
$color-botticelli: #d4e4ed;
$color-catskill: #f2f4f8;
$color-chateau: #42be65;
$color-forest: #1f8a3e;
$color-pastel-green: #63eb9a;
$color-geyser: #dce0e5;
$color-grey-chateau: #a0a6ac;
$color-hawkes-blue: #d7edfd;
$color-jewel: #198038;
$color-kumera: #7b6a1c;
$color-loblolly: #c1c7cd;
$color-lochmara: #007dbc;
$color-lynch: #5c7584;
$color-mercury: #e5e5e5;
$color-nandor: #435350;
$color-nevada: #696f77;
$color-oslo: #858c93;
$color-outer-space: #2c3235;
$color-persian-green: #00b485;
$color-picton-blue: #35a5f3;
$color-pippin: #ffe3e8;
$color-pizza: #ff8d00;
$color-carry: #ffead1;
$color-shark: #1d1f20;
$color-silver: #c7c7c7;
$color-skeptic: #d4eddc;
$color-supernova: #fcc30b;
$color-sycamore: #938334;
$color-torch: #f50024;
$color-tutu: #ffebf5;
$color-venice: #085387;
$color-paarl: #ad4f2b;
$color-shark-transparent: #1d1f20f2;
$color-shark-trans-light: #292a2beb;
$color-pearl-lusta: #fbf4d5;
$color-corn: #ecae04;
$color-bush: #f1bfc5;

// Miscellaneous
$box-shadow: 3px 3px 10px 0 rgba(30, 42, 54, 0.3);
$box-shadow-tiny: 2px 2px 8px 0 rgba(30, 42, 54, 0.1);
$header-height: 75px;
$footer-height: 60px;
$nav-height: 40px;
$viewport-height: calc(100vh - #{$header-height} - #{$nav-height} - #{$footer-height});
$viewport-mobile-height: calc(100vh - #{$header-height} - #{$footer-height});
$button-height: 40px;
$field-height: 40px;
$cell-height: 56px;
$padding-default: 20px;
$border-radius-small: 4px;
$icon-size: 18px;
$aside-width: 300px;

// Breakpoints
$breakpoint-xxl: 1536px;
$breakpoint-xl: 1280px;
$breakpoint-lg: 1023px;
$breakpoint-md: 768px;
$breakpoint-sm: 640px;
