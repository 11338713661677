@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.content {
  padding: $padding-default;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }

  .label {
    font-weight: 700;
  }
}
