@import "~antd/dist/antd.css";
@import "./normalize.scss";
@import "./variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");

body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.react-datepicker__monthPicker {
  display: flex;
}

// Fix for table sort header, which set above dropdowns
.ant-table-column-title {
  z-index: auto;
}

// Scrollbar customization
body {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 50px;
  }
}

// General scrollbar for whole page
html {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-oslo;
    border-radius: 8px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-nandor;
  }
}

// Scrollbar for components
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: $color-loblolly;
  border-radius: 4px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: $color-geyser;
}

// SVG always by default uses color from current container
svg {
  fill: currentColor;
}

// Hide default input number buttons
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

// UI Kit styles
.maintenance-banner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  background-color: #689e32;
  padding: 0.625rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #f2f4f8;
}
.w-7 {
  width: 1.75rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.cursor-pointer {
  cursor: pointer;
}
.text-white {
  color: #f2f4f8;
}
