@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

$info-width: 400px;

@mixin cover {
  width: 100%;
  height: 200px;
}

.container {
  width: 100%;
  height: $viewport-height;
  position: relative;
  overflow: hidden;
}

.info {
  position: absolute;
  top: calc(50% - 185px);
  left: $padding-default;
  z-index: 10;
  background-color: $color-shark;
  width: $info-width;
  cursor: default;
  transition: 0.2s all linear;

  .buttonSwitch {
    position: absolute;
    background-color: $color-shark;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: 0.2s all linear;
    padding: 0 10px;

    svg {
      fill: $color-catskill;
      width: 12px;
      height: 12px;
      transition: 0.2s fill linear;
    }

    &:hover {
      color: $color-picton-blue;

      svg {
        fill: $color-picton-blue;
      }
    }

    &.buttonHide {
      z-index: 10;
    }

    &.buttonShow {
      z-index: 5;
    }
  }

  .title {
    font-size: 24px;
    line-height: 32px;
    padding: $padding-default $padding-default 0;
  }

  .description {
    font-size: 14px;
    line-height: 21px;
    margin: 10px 0 15px;
    padding: 0 $padding-default;
  }

  .counters {
    display: flex;
    gap: $padding-default;
    background-color: $color-outer-space;
    padding: $padding-default;

    .counter {
      width: 50%;

      .label {
        font-size: 14px;
        line-height: 21px;
        min-height: 42px;
        margin: 0 0 5px;
      }

      .value {
        font-size: 36px;
        line-height: 36px;
      }

      &:nth-child(1) {
        color: $color-picton-blue;
      }

      &:nth-child(2) {
        color: $color-persian-green;
      }
    }
  }

  .control {
    display: flex;
    gap: $padding-default;
    padding: $padding-default;

    .dropdown {
      width: 100%;
    }

    button {
      flex-shrink: 0;
    }
  }

  &.hidden {
    transform: translateX(calc(-#{$info-width} - #{$padding-default}));

    .buttonSwitch {
      &.buttonShow {
        transform: translateX(77px);
      }
    }
  }
}

.popup {
  position: absolute;
  top: 0;
  right: -23vw;
  z-index: 10;
  height: $viewport-height;
  background-color: $color-shark;
  width: 23vw;
  transition: 0.1s all linear;

  &.active {
    transform: translateX(-23vw);
  }

  .title {
    background-color: $color-outer-space;
    padding: $padding-default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
    font-size: 20px;
    cursor: default;

    .text {
      @include text-cropping;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .countryCover {
    @include cover;
    background: url("../../assets/img/car.png") no-repeat center;
  }

  .workshopCover {
    @include cover;
    background: url("../../assets/img/jungle.png") no-repeat center;
  }

  .cover {
    @include img-centered(200px);
  }

  .button {
    justify-content: space-between;
  }

  .content {
    padding: $padding-default;
    cursor: default;

    .subtitle {
      font-weight: 600;
      font-size: 16px;
      margin: 0 0 10px;
    }

    .item {
      font-size: 14px;
      margin: 0 0 5px;
      color: $color-white;
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        fill: $color-catskill;
      }
    }
  }

  &.popupWorkshop {
    z-index: 11;
  }

  &.popupCountry {
    .item {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.city {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  cursor: pointer;

  svg {
    fill: $color-white;
  }
}

:global {
  .mapboxgl-ctrl-logo {
    display: none !important;
  }

  .mapboxgl-ctrl-compass {
    display: none !important;
  }
}

@media (max-width: $breakpoint-lg) {
  .container {
    height: $viewport-mobile-height;
    position: relative;
    z-index: 1;
  }

  .info {
    left: 0;
    width: 100%;

    &.hidden {
      transform: translateX(-100%);
    }
  }

  .popup {
    width: 100%;
    right: -100%;
    height: $viewport-mobile-height;

    &.active {
      transform: translateX(-100%);
    }
  }
}
