@import "@assets/styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .switcher {
    position: relative;
    width: 48px;
    height: 22px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 22px;
      border-radius: 11px;
      background-color: $color-loblolly;
      box-shadow: $box-shadow-tiny;
      transition: 0.2s;
    }

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background-color: $color-geyser;
      box-shadow: $box-shadow-tiny;
      transition: 0.2s;
    }

    &.active {
      &:before {
        background-color: $color-persian-green;
      }
      &:after {
        background-color: $color-skeptic;
      }
    }
  }

  .input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0;
  }

  .text {
    position: relative;
    font-size: 14px;
    color: $color-white;
    line-height: 20px;

    &.stLeft {
      text-align: right;
      padding-right: 10px;
    }

    &.stRight {
      text-align: left;
      padding-left: 5px;
    }
  }
}

.input:checked + .switcher:after {
  left: 28px;
}
