@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

$panel-size: 46px;

@mixin panel {
  align-items: center;
  display: flex;
  height: $panel-size;
}

.pagination {
  display: flex;
  align-items: center;
  color: $color-shark;
  font-size: 14px;
  cursor: default;
  border-bottom: 1px solid $color-geyser;

  .select {
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    text-align: center;
    margin: 0 8px;

    option {
      color: $color-shark;
    }
  }

  .items {
    @include panel;
    border-right: 1px solid $color-geyser;
    margin: 0 20px 0 0;
    padding: 0 4px 0 8px;
  }

  .pages {
    @include panel;
    border-left: 1px solid $color-geyser;
    margin-left: auto;
    padding: 0 12px 0 0;
  }

  button {
    width: $panel-size;
    height: $panel-size;
    text-align: center;
    background: transparent;
    padding: 0;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: 0.2s all linear;
    border-left: 1px solid $color-geyser;
    color: $color-shark;

    svg {
      width: 16px;
    }

    &:hover {
      background-color: $color-catskill;
    }

    &:disabled {
      pointer-events: none;
      color: $color-oslo;
    }
  }
}
