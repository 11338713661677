@import "@assets/styles/variables.scss";

.main {
  color: $color-catskill;
  min-height: $viewport-height;

  &.office {
    background:
      linear-gradient(90deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)),
      url("../../assets/img/office.webp") no-repeat center;
    background-size: cover;
    background-attachment: fixed;
  }

  &.customer {
    background:
      linear-gradient(90deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)),
      url("../../assets/img/customer.webp") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center -80px;
  }

  &.home {
    background:
      linear-gradient(90deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)),
      url("../../assets/img/home.webp") no-repeat center;
    background-size: cover;
    background-attachment: fixed;
  }

  &.none {
    background-color: #333333;
  }

  &.withoutAuth {
    height: 100vh;
  }

  .container {
    padding: $padding-default;

    &.withoutPaddings {
      padding: 0;
    }
  }

  .tooltip {
    max-width: 300px;
    background-color: $color-hawkes-blue;
    color: $color-shark;
    font-weight: 500;
    // opacity: 1; // Note: because we have some weird bug with styles
    padding: 5px 7px;
    border-radius: $border-radius-small;
    box-shadow: $box-shadow;
    text-align: center;

    b {
      font-weight: 700;
    }
  }

  @media (max-width: $breakpoint-lg) {
    min-height: $viewport-mobile-height;
  }
}
