@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.totals {
  background-color: $color-white;
  min-width: 450px;
  cursor: default;

  .row {
    @include field;
    border-bottom: 1px solid $color-geyser;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    transition: 0.2s background-color linear;

    .formRow {
      display: flex;
      gap: 10px;
    }

    &:first-child {
      font-weight: 600;
    }

    &:last-child {
      font-weight: 600;
      background-color: $color-skeptic;
    }

    &.disabled {
      background-color: $color-geyser;
    }
  }
}

.percentageField {
  border: 1px solid $color-picton-blue;
  outline: 0;
  width: 40px;
  padding: 0;
  transition: 0.2s all linear;
  height: 40px;
  margin: -10px 0;
  text-align: center;
}
