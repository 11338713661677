@import "@assets/styles/variables.scss";

.streamContainer {
  min-height: $viewport-height;
  padding: 40px;

  @media (max-width: $breakpoint-lg) {
    min-height: $viewport-mobile-height;
  }
}
