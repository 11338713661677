@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.jobCard {
  padding: $padding-default;

  .jobCardTime {
    margin: 0 0 20px;
  }

  .jobCardRow {
    display: flex;
    margin: 6px 0;
    gap: $padding-default;

    .jobCardLabel {
      width: 35%;
      font-size: 14px;
      color: $color-grey-dark-2;
      line-height: 18px;
      padding: 4px 0 0;
      flex-shrink: 0;
    }

    .jobCardValue {
      @include text-cropping;
      width: 65%;
      display: flex;
      align-items: center;
      gap: 8px;

      .colorDot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
      }
    }
  }
}

.syncBlock {
  text-align: center;
  color: $color-nevada;

  .button {
    display: inline-block;
    color: $color-lochmara;
  }
}

.syncButton {
  &:disabled {
    svg {
      @include rotation;
    }
  }
}
