@import "@assets/styles/variables.scss";

.page {
  background-color: $color-catskill;
  color: $color-shark;
  font-size: 16px;
  line-height: 24px;
  padding: $padding-default;
  min-height: calc(#{$viewport-height} - 40px);

  &.ca {
    background-color: $color-hawkes-blue;
  }
}
