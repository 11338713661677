@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.field {
  position: relative;

  .error {
    @include input-error;
  }
}

:global {
  .intl-tel-input.allow-dropdown {
    width: 100%;

    .intl-tel-element {
      @include input;

      &.intl-tel-element-error {
        background-color: $color-pippin;
        border-bottom-color: $color-alizarin;
      }
    }

    .flag-container .arrow:after {
      color: $color-shark;
    }

    .country-list {
      border: 0;
      font-size: 14px;
      color: $color-shark;
    }

    .selected-flag {
      border-right: 1px solid $color-grey-light-3;
      height: 20px;
      margin: 9px 0 0;
      display: flex !important;
      background-color: transparent !important;
      outline: none;

      .selected-dial-code {
        display: block;
        padding: 0 0 0 5px;
        cursor: default;
        height: 20px;
        line-height: 20px;
        color: $color-shark;
      }

      .arrow {
        order: -1;
        margin: 0 7px 0 0;
      }

      &:hover {
        background: none;
      }
    }

    &.invalid {
      .intl-tel-element {
        background-color: $color-pippin;
      }
    }
  }
}
