@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.stat {
  display: flex;
  padding: 15px;
  border: 1px solid $color-geyser;
  cursor: default;

  .count {
    padding: 0 15px;
    border-right: 1px solid $color-geyser;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border: 0;
    }

    .value {
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-weight: 300;

      .iconWrapper {
        display: flex;
        &.sycamore {
          color: $color-sycamore;
        }
        &.bluch {
          color: $color-bluch;
        }
        &.lochmara {
          color: $color-lochmara;
        }
        &.jewel {
          color: $color-jewel;
        }
      }
    }

    .label {
      margin: 10px auto 0;
      text-align: center;
      line-height: 1;
    }

    svg {
      width: $icon-size;
      height: $icon-size;
    }

    .checkIn {
      fill: $color-sycamore;
    }

    .checkOut {
      fill: $color-bluch;
    }
  }
}
