@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.title {
  font-size: 30px;
  line-height: 40px;
}

.filters {
  margin: $padding-default 0;
  display: flex;
  gap: $padding-default;
  align-items: end;

  .resetButton {
    margin: 0 0 13px;
  }
}

.content {
  background-color: $color-white;
}

.stat {
  display: flex;
  align-items: center;
  gap: $padding-default;
  padding: $padding-default;
  border-bottom: 1px solid $color-geyser;
  cursor: default;

  .statInfoBlock {
    width: 20%;

    .subtitle {
      @include widget-title;
      margin: 0 0 $padding-default;
    }
  }

  .statBlock {
    border-left: 1px solid $color-geyser;
    padding-left: $padding-default;
    width: 16%;

    .count {
      margin-top: $padding-default;
      font-size: 30px;
      font-weight: 300;
    }
  }
}

.widgets {
  display: flex;
  border-top: 1px solid $color-catskill;
  flex-wrap: wrap;

  .block {
    width: 50%;
    border-bottom: 1px solid $color-catskill;
    &:nth-child(2n + 1) {
      border-right: 1px solid $color-catskill;
    }
  }
}
