@import "@assets/styles/variables.scss";

.wrapper {
  position: relative;

  .loaderContainer {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.full {
      position: fixed;
      background-color: $color-catskill;
    }
  }

  .loader {
    position: absolute;
    z-index: 6;
    background-color: $color-white;
    padding: 30px;
    box-shadow: $box-shadow;
    border-radius: 45px;
    top: calc(50% - 45px);
    left: calc(50% - 120px);
  }

  .container {
    &.disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    }
  }
}
