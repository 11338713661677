@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.checkbox {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: inherit;
    cursor: pointer;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 2px;
      background-color: $color-white;
      border-radius: $border-radius-small;
      border: 1px solid $color-geyser;
    }

    span {
      @include text-cropping;
    }
  }

  .input:checked + .label::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 75% 75%;
    background-color: $color-lochmara;
  }
}
