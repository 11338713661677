@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.container {
  height: 100%;

  &.in {
    background-color: $color-battery;
    border-left: 4px solid $color-sycamore;

    &.info {
      color: $color-bluch;
    }
  }

  &.out {
    background-color: $color-tutu;
    border-left: 4px solid $color-bluch;

    &.info {
      color: $color-bluch;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
}

.extraSmallBox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallBox {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px;
  line-height: 1.5;
}

.box {
  padding: 6px;
  line-height: 1.5;
}

.content {
  font-size: 12px;
}

.label {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  @include text-cropping;
}

.time {
  text-align: center;
}

.link {
  color: $color-shark;

  &:hover {
    color: $color-picton-blue;
  }
}

.vehicle {
  @include text-cropping;
}

.info {
  @include text-cropping;
}

.icon {
  width: 20px;
  flex-shrink: 0;
}

.tooltip {
  width: 100%;
}
