@import "@assets/styles/variables.scss";

.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px;

  .title {
    font-size: 30px;
    line-height: 40px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 15px 0 0;
    cursor: default;

    div {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        fill: $color-nevada;
      }
    }
  }
}
